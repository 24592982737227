import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function DeliverySupportAgent () {
  return (
    <CareerLayout title='Delivery Support Agent' location='Remote'>
      <h2>Overview</h2>
      <p>As a Driver Support Agent at Trexity, you’ll work autonomously to help troubleshoot and resolve tricky situations and product bugs. Alongside your team, you will work to assist hundreds of drivers and merchants weekly, making sure they get the most out of using Trexity by answering questions, supporting their needs, and troubleshooting technical issues. You’ll also collect product insights to help the engineering and design teams create the best product for our users.</p>
      <p className='mb'>You have open availability. This is a full-time, remote role that requires you to occasionally work on evenings and weekends.</p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Help drivers through the onboarding process</li>
        <li>Support applicants and drivers with your knowledge of the Trexity platform and any local market nuances</li>
        <li>Deal with all modes of support (chat, phone, and email)</li>
        <li>Perform pre and post applicant outreach to get drivers approved and ready to deliver</li>
        <li>Work with current drivers to solve/troubleshoot any issues that they may have</li>
        <li>Help in improving processes, so we always optimize for better user experience</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>1+ years of experience in a customer service role such as in retail, technical support or hospitality</li>
        <li>Excellent, clear verbal and written communication skills</li>
        <li>You are above average in technological fluency, including smartphone application navigation, computer function navigation, such as using hotkeys for copy/paste, opening multiple tabs, email</li>
        <li>You are customer-oriented and you love helping people</li>
        <li>Skills you’ll need: patience, problem identifying and solving, de-escalating situations, teamwork, and being a self-starter</li>
      </ul>

      <h3>Bonus points</h3>
      <ul className='trx-bullet mb'>
        <li>Proficiency in other languages</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
        <li>Remote work</li>
      </ul>
    </CareerLayout>
  )
}
